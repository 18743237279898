:root {
	--main-bg-color: #f5f5dc;
}

body,
:root {
	background-color: var(--main-bg-color);
	height: 100%;
}

body {
	font-family: 'Press Start 2P', sans-serif;
}

.answer-btn {
	height: 100px;
}

.answer-btn:nth-of-type(1) {
	background: violet;
}

.answer-btn:nth-of-type(2) {
	background: #f9bc60;
}

.answer-btn:nth-of-type(3) {
	background: #00a2ff;
}

.answer-btn:nth-of-type(4) {
	background: slateblue;
}

.postquestion {
	font-size: 36px;
	text-align: center;
}

.nes-container.with-title > .title {
	background-color: var(--main-bg-color);
}

.icon-list {
	display: flex;
	overflow: scroll;
	gap: 15px;
}

.icon-list > input[type='radio'] {
	height: inherit;
}

.player-lobby {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 100px;
	margin-bottom: 24px;
	justify-content: space-around;
}

.player-lobby > p {
	font-size: 24px;
}

.player-lobby > p > i {
	margin-right: 32px;
	height: 66px;
}
